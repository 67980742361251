import { useEffect, useState } from "react";
import { useState as useReducerState } from "../hooks/useReducer";
import DispositionCard from "./DispositionCard";

const DispositionModal = () => {
  const { dispositionModal, dispositionModalOpen = false } = useReducerState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("modal-open", dispositionModalOpen);
    setLoaded(dispositionModalOpen);
    if (!dispositionModalOpen) {
      document.getElementById("modal-disposition").scrollTop = 0;
    }
  }, [dispositionModalOpen]);

  return (
    <div
      id="modal-disposition"
      className={`disposition-modal ${
        dispositionModalOpen
          ? `disposition-modal--open disposition-modal--open--${dispositionModal.color}`
          : ""
      } ${
        dispositionModal !== null
          ? `disposition-modal--${dispositionModal.color}`
          : ""
      } ${loaded ? "disposition-modal--animate" : ""}`}
      aria-hidden={dispositionModalOpen ? false : true}
      aria-disabled={dispositionModalOpen ? false : true}
      tabIndex={dispositionModalOpen ? 0 : -1}
    >
      <div className="container">
        <div className="row">
          <div className="col-1o1">
            <DispositionCard {...dispositionModal} modal={true} hideTheme={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DispositionModal;
