import {useState, useDispatch, useRefProvider} from "../hooks/useReducer";
import { AreaIcon, Filter } from "./Icons";
import IconButton from "./IconButton";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../hooks/useIsMobile";

const AreaFilter = ({ count }) => {
  const navigate = useNavigate();
  const { areas } = useState();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { setRef, focusRef } = useRefProvider();
  const handleFilterButtonClick = (id, state) => {
    navigate("/maatregelen");
    dispatch({ type: "set_area", payload: { id, state } });
  };

  const handleOpenFilterPanelClick = () => {
    focusRef(`areaFirstEl`);
    dispatch({ type: "open_area_filter" });
  };

  return (
    <div className="area-filter" id="main-content">
      <div className="container">
        <div className="row">
          <div className="col-1">
            { isMobile &&
                <div className="area-filter__content-mobile">
                  <h2 role="status">{count} gevonden resultaten</h2>
                  <IconButton
                      onClick={handleOpenFilterPanelClick}
                      ariaLabel={"Open het gebied filter"}
                      ref={setRef("areaTrigger")}
                      >
                    <Filter />
                  </IconButton>
                </div>
            }
            <div className="area-filter__content">
              <h3 className="area-filter__title">Gebied:</h3>
              <ul className="area-filter__list">
              {areas.map(({ value, label, active }, index) => (
                  <li key={index}>
                    <button
                        className={`area-filter__button ${
                            active ? "area-filter__button--active" : ""
                        }`}
                        aria-current={active ? true : false}
                        onClick={() => handleFilterButtonClick(value, !active)}
                    >
                      <span className="area-filter__button-icon">
                        <AreaIcon id={value} />
                      </span>
                      {label}
                    </button>
                  </li>
              ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaFilter;
