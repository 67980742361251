import { useEffect, useRef, useState } from "react";
import { useState as useGlobalState, useDispatch, useRefProvider } from "../hooks/useReducer";
import YouTube from "react-youtube";
import CloseIcon from "../assets/close-icon.svg";
import { useLocation } from "react-router-dom";
import VideoTranscript from "./VideoTranscript";

function YouTubeGetID(url) {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

const VideoModal = () => {
  const { pathname } = useLocation();
  const [videoId, setVideoId] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const { videoModal } = useGlobalState();
  const dispatch = useDispatch();
  const video = useRef();
  const videoModalRef = useRef();
  const { videoTriggerRef, videoModalFirstElRef } = useRefProvider();
  useEffect(() => {
    document.body.classList.toggle("modal-open", videoModal !== null);
    if (videoModal !== null) {
      setVideoId(YouTubeGetID(videoModal));
      setCurrentLocation(pathname);
    } else {
      if (video.current) {
        video.current.getInternalPlayer().pauseVideo();
      }
    }
  }, [videoModal, pathname]);

  const closeModal = () => {
    if (videoTriggerRef?.current) {
      videoTriggerRef.current.focus();
    }
    dispatch({ type: "close_video" });
  };

  const onPlayerReady = (event) => {
    const player = event.target;
    const videoTitle = player?.getVideoData()?.title;
    const iframe = event?.target?.getIframe();
    if (!iframe) {
      return;
    }
    iframe.setAttribute("title",  `Video ${videoTitle}`);
  };

  const handleOnKeyDown = (event) => {
    const { key, keyCode, shiftKey } = event;
    const player = video?.current.getInternalPlayer();
    player.isPlaying = player.isPlaying || false;

    if (key === "Escape") {
      closeModal();
    }
    if (key === "K" || keyCode === 75) {
      video?.current.getInternalPlayer().pauseVideo();
    }

    if (shiftKey) {
      if (document.activeElement === videoModalFirstElRef.current) {
        event.preventDefault();
      }
    }

    if (key === " ") {
      event.preventDefault();
      if (!player) {
        return;
      }
      // Check if the video is playing and toggle accordingly
      if (player.isPlaying) {
        player.pauseVideo();
        player.isPlaying = false;
      } else {
        player.playVideo();
        player.isPlaying = true;
      }
    }
    if (key === "m") {
      if (!player) {
        return;
      }
      // Check if the video is playing and toggle accordingly
      if (player.isPlaying) {
        player.mute();
        player.isPlaying = false;
      } else {
        player.unMute();
        player.isPlaying = true;
      }
    }
  }
  const opts = {
    playerVars: {
      autoplay: 1,
      disablekb: 1,
    },
  };

  return (
    <div
        className={`video-modal ${videoModal !== null ? "video-modal--open" : ""}`}
        ref={videoModalRef}
        onKeyDown={handleOnKeyDown}
    >
      <button
          aria-label={"Sluit de video"}
          ref={videoModalFirstElRef}
          onClick={closeModal}
          className="video-modal__close-icon">
        <img
            src={CloseIcon}
            alt="Sluit de video"
        />
      </button>
      <div className="video-modal__youtube" tabIndex={0}>
        {videoId !== null && (
            <YouTube
                ref={video}
                videoId={videoId}
                opts={opts}
                onReady={onPlayerReady}
                onKeyDown={handleOnKeyDown}
                aria-describedby="video-description"
            />
        )}
      </div>

      {/* Accessibility video transcript section */}
      {videoId && <VideoTranscript videoId={videoId}/>}
    </div>
  );
};

export default VideoModal;
