import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import DispositionCard from "../components/DispositionCard";
import AreaFilter from "../components/AreaFilter";
import SearchFilter from "../components/SearchFilter";
import { getDispositions } from "../api";
import {useRefProvider, useState as useGlobalState} from "../hooks/useReducer";
import DispositionPagination from "../components/DispositionPagination";
import { useDispatch } from "../hooks/useReducer";
import AreaModal from "../components/AreaModal";
import useIsMobile from "../hooks/useIsMobile";
import DispositionModal from "../components/DispositionModal";
import LogoDelfslandComponent from "../components/LogoDelfsland";
import SkipLink from "../components/SkipLink";
import HeadMeta from "../components/HeadMeta";

function getPageValue(value) {
  let queryParams = new URLSearchParams(value);
  const result = queryParams.has("page")
      ? isNaN(parseInt(queryParams.get("page")))
          ? 0
          : parseInt(queryParams.get("page"))
      : 0;
  return result;
}

function getMaxPages(count) {
  const rest = count % 6;
  return (count - rest) / 6 + (rest === 0 ? 0 : 1);
}

function DispositionIndex() {
  const [dispositions, setDispositions] = useState({
    count: 0,
    items: [],
  });
  const { dispositionSearch, areas } = useGlobalState();
  const { search, pathname } = useLocation();
  const [page, setPage] = useState(getPageValue(search));
  const [maxPages, setMaxPages] = useState(getMaxPages(0));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { focusRef } = useRefProvider();

  useEffect(() => {
    setPage(getPageValue(search));
  }, [search]);

  useEffect(() => {
    return () => {
      dispatch({ type: "deactivate_all_filters" });
    };
  }, [pathname, dispatch]);

  useEffect(() => {
    const filteredAreas = areas.filter(({ active }) => active);

    const fetchDispositions = async () => {
      try {
        const [ds] = await Promise.all([
          getDispositions(dispositionSearch, filteredAreas),
        ]);

        setDispositions({ count: ds.length, items: ds });
        setMaxPages(getMaxPages(ds.length));
      } catch (error) {
        console.error("Error fetching dispositions:", error);
      }
    };

    fetchDispositions();
  }, [dispositionSearch, areas]);

  const handlePageLink = (newPage) => {
    navigate(`/maatregelen?page=${newPage}`);
    window.scrollTo({ top: 0, behavior: "instant" });
    // focus skip-link when next/prev page
    focusRef("skip-link");
  };

  const renderDispositionCards = () => {
    const startIndex = page * 6;
    const endIndex = startIndex + 6;

    return dispositions.items
        .slice(startIndex, endIndex)
        .map((disposition) => (
            <li
                className="col-1o1 col-l-1o2 col-xl-1o3"
                key={disposition.disposition.id}
            >
              <DispositionCard {...disposition.disposition} {...disposition} />
            </li>
        ));
  };

  return (
      <div className="DispositionIndex">
        <HeadMeta pageTitle="Maatregelen | Waterwerk WBP6 Hoogheemraadschap van Delfland" />
        <SkipLink targetId="main-content" />
        <header className="DispositionIndex-header">
          <div className="container">
            <div className="row">
              <div className="col-1o2">
                <LogoDelfslandComponent />
              </div>
              <div className="col-1o2 text-align-right">
                <Link to="/" aria-hidden={true} tabIndex="-1">
                  <Logo />
                </Link>
              </div>
            </div>
          </div>
        </header>
        <AreaFilter count={dispositions.count} />
        <div className="DispositionIndex-results">
          <div className="container">
            <div className="row">
              <div className="col-1o2 DispositionIndex__counter">
                <h2 role="status">{dispositions.count} gevonden resultaten</h2>
              </div>
              <SearchFilter />
            </div>
            <ul className="row">
              {renderDispositionCards()}
            </ul>
            <div className="row">
              <div className="col-1">
                <DispositionPagination
                    current={page}
                    maximum={maxPages}
                    request={handlePageLink}
                />
              </div>
            </div>
          </div>
        </div>
        {isMobile && <AreaModal />}
        {isMobile && <DispositionModal />}
      </div>
  );
}

export default DispositionIndex;
