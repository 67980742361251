import { useEffect, useState } from 'react';

export const useFocusBoundaries = (containerRef, dispositionModalOpen) => {
    const [firstElement, setFirstElement] = useState(null);
    const [lastElement, setLastElement] = useState(null);

    useEffect(() => {
        // Only execute if the modal is open and containerRef is valid
        if (dispositionModalOpen && containerRef.current) {
            // Set a timeout to delay the execution
            const timer = setTimeout(() => {
                // Define focusable selectors
                const focusableSelectors = [
                    'a[href]',
                    'button',
                    'input',
                    '.context-popper__button'
                ];

                // Find all focusable elements within the container
                const elements = Array.from(containerRef.current.querySelectorAll(focusableSelectors.join(', ')));

                // Determine the first and last focusable elements
                const first = elements[0] || null;
                const last = elements[elements.length - 1] || null;

                // Update state with first and last elements
                setFirstElement(first);
                setLastElement(last);

            }, 300);

            // Cleanup function to clear the timeout if the effect is re-run
            return () => clearTimeout(timer);
        } else {
            // Clear the first and last elements if the modal is closed
            setFirstElement(null);
            setLastElement(null);
        }
    }, [containerRef, dispositionModalOpen]);
    return { firstElement, lastElement };
};

