import { useEffect } from "react";
import {useState, useDispatch, useRefProvider} from "../hooks/useReducer";
import { AreaIcon } from "./Icons";
import IconButton from "./IconButton";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import CloseButton from "./CloseButton";
import useIsMobile from "../hooks/useIsMobile";

const AreaModal = () => {
  const navigate = useNavigate();
  const { areaFilterOpen, areas } = useState();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { setRef, focusRef } = useRefProvider();

  useEffect(() => {
    document.body.classList.toggle("modal-open", areaFilterOpen);
    if (!areaFilterOpen) {
      document.getElementById("modal-area").scrollTop = 0;
    }
  }, [areaFilterOpen]);

  const handlers = useSwipeable({
    onSwiped: () => dispatch({ type: "close_area_filter" }),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleCloseModal = () => {
    focusRef("areaTrigger");
    dispatch({type: "close_area_filter"})
  };

  const handleFilterButtonClick = (id, state) => {
    navigate("/maatregelen");
    dispatch({ type: "set_area", payload: { id, state } });
  };

  const handleResetFilters = () => {
    dispatch({ type: "deactivate_all_filters" });
  };
  const handleOnKeyDownAreaModal = (event) => {
    const { key } = event;
    if (key === "Escape") {
      handleCloseModal();
    }
  }

  return (
    <div
      id="modal-area"
      className={`area-modal ${areaFilterOpen ? "area-modal--open" : ""}`}
      aria-hidden={areaFilterOpen ? false : true}
      onClick={handleCloseModal}
      onKeyDown={handleOnKeyDownAreaModal}
    >
      <div
        className="area-modal__content"
        onClick={(e) => e.stopPropagation()}
        {...handlers}
      >
        {isMobile && <CloseButton ariaLabel={"Sluit het gebied filter"} onClick={handleCloseModal} closeButtonRef={setRef("areaFirstEl")} />}
        <div className="container">
          <div className="row">
            <div className="col-1">
              <div className="area-modal__title-bar">
                <h2 className="area-modal__title">Gebieden</h2>
                <button
                  className="area-modal__title-button"
                  onClick={handleResetFilters}
                >
                  reset alle filters
                </button>
              </div>
              <ul>
                {areas.map(({ value, label, active }, index) => (
                  <li key={index}>
                    <button
                      className={`area-filter__button ${
                        active ? "area-filter__button--active" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFilterButtonClick(value, !active);
                      }}
                      aria-current={active ? true : false}
                    >
                      <span className="area-filter__button-icon">
                        <AreaIcon id={value} />
                      </span>
                      {label}
                    </button>
                  </li>
                ))}
              </ul>
              <IconButton
                className="area-modal__text-button"
                glow={true}
                onClick={handleCloseModal}
              >
                SLUITEN
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaModal;
