import {useState, useEffect, useRef} from "react";
import {useDispatch, useRefProvider, useState as useReducerState} from "../hooks/useReducer";
import {getImage} from "../helpers/imageParse";
import {AreaIcon} from "./Icons";
import wordListParser from "../helpers/wordList";
import ReactHtmlParser from "react-html-parser";
import {getThesaurus} from "../api";
import useIsMobile from "../hooks/useIsMobile";
import {useFocusBoundaries} from "../hooks/useFocusBoundaries";

const DispositionCard = (props) => {
    const {inhoud = {}, title, themeTitle, modal = false, id, hideTheme = false, slider = false} = props;
    const {
        toelichting,
        gebied = [],
        hoofdafbeelding,
        periodeStart = "",
        periodeEind = "",
    } = inhoud;

    let color = props.color;
    const isMobile = useIsMobile();

    const year = periodeStart.slice(0, 4) + " - " + periodeEind.slice(0, 4);
    const image = getImage(hoofdafbeelding);
    const [content, setContent] = useState(ReactHtmlParser("<p>Mother</p>"));
    const { setRef, focusRef } = useRefProvider();
    const {dispositionModalOpen, areas, dispositionColors} = useReducerState();
    const popupRef = useRef(null);
    const { lastElement } = useFocusBoundaries(popupRef, dispositionModalOpen);

    useEffect(() => {
        if (toelichting !== undefined) {
            getThesaurus()
                .then((thesaurusData) => {
                    setContent(wordListParser(toelichting, thesaurusData));
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [toelichting]);

    const [contentOpen, setContentOpen] = useState(false);
    const [areaOpen, setAreaOpen] = useState(false);
    const dispatch = useDispatch();

    if (!!id) {
        const r = !!dispositionColors[id]
            ? dispositionColors[id]
            : {color, title: ""};
        color = r.color;
    }

    const handleContentClick = () => {
        if (!contentOpen) {
            setAreaOpen(false);
        }
        setContentOpen((old) => !old);
    };

    const handleAreaClick = () => {
        if (!areaOpen) {
            setContentOpen(false);
        }
        setAreaOpen((old) => !old);
    };

    const closeModal = () => {
        focusRef(`${id}-no-modal`);
        dispatch({
            type: "close_disposition_modal",
        });
    }
    const openModal = () => {

        focusRef(`popup-modal`);
        dispatch({
            type: "open_disposition_modal",
            payload: {themeTitle, inhoud, title, color, id},
        });
    }

    const handleMobileClick = () => {
        if (dispositionModalOpen) {
            closeModal();
        } else {
            openModal();
        }
    };
    const handleOnKeyDown = (event) => {
        const { key } = event;
        if (!dispositionModalOpen) {
            return;
        }
        if (key === "Escape") {
            closeModal();
        }
    }
    const handleParentModalEl = (event) => {
        const { key, shiftKey } = event;
        if (key === "Tab") {
            // If the last element is focused and Shift key is not pressed, prevent default behavior
            if (lastElement === document.activeElement && !shiftKey) {
                event.preventDefault();
            }
        }
        if (key === "Escape") {
            closeModal();
        }
    };

    return (
        <ul
            className={`dispositionCard dispositionCard--${color} ${modal ? "dispositionCard--modal" : ""}`}
            ref={modal ? popupRef : null}
            onKeyDown={handleParentModalEl} >
                <li className="dispositionCard__theme">
                {!hideTheme && `Thema: ${themeTitle}`}
                { isMobile && <button
                    className="dispositionCard__mobile-button"
                    onClick={handleMobileClick}
                    onKeyDown={handleOnKeyDown}
                    style={hideTheme ? {top: "-20px"} : {}}
                    aria-expanded={modal || dispositionModalOpen}
                    aria-label={modal ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}
                    tabIndex={!modal || dispositionModalOpen ? 0 : -1}
                    ref={setRef(modal ? `popup-modal` : `${id}-no-modal`)}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden={true}
                    >
                        <title>{modal ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}</title>
                        <circle
                            cx="12"
                            cy="12"
                            r="11"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                        />
                        {!modal && (
                            <rect x="11" y="8" width="2" height="8" fill="currentColor"/>
                        )}
                        <rect
                            x="16"
                            y="11"
                            width="2"
                            height="8"
                            transform="rotate(90 16 11)"
                            fill="currentColor"
                        />
                    </svg>
                </button>}
            </li>

            <li className="dispositionCard__title">
                <span className="dispositionCard__title-inner">{`Maatregel: ${title}`}</span>
            </li>

            <li className="dispositionCard__content">
                <span
                    className={`dispositionCard__content-inner ${contentOpen ? "dispositionCard__content-inner--open" : ""}`}
                >
                  {content}
                </span>
                {!dispositionModalOpen && !modal && <button
                    className="icon-button dispositionCard__button"
                    onClick={handleContentClick}
                    aria-expanded={contentOpen}
                    aria-label={contentOpen ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>{contentOpen ? `Verberg informatie over maatregel: ${title}` : `Toon meer informatie over maatregel: ${title}`}</title>
                        <circle
                            cx="12"
                            cy="12"
                            r="11"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                        />
                        {!contentOpen && (
                            <rect x="11" y="8" width="2" height="8" fill="currentColor"/>
                        )}
                        <rect
                            x="16"
                            y="11"
                            width="2"
                            height="8"
                            transform="rotate(90 16 11)"
                            fill="currentColor"
                        />
                    </svg>
                </button>}
            </li>

            <li className="dispositionCard__year">
                <time dateTime={year}>{year}</time>
            </li>

            <li className="dispositionCard__area">
                <span className="dispositionCard__area-inner">Gebied: </span>
                {!dispositionModalOpen && !modal && <button
                    className="icon-button dispositionCard__button"
                    onClick={handleAreaClick}
                    aria-expanded={areaOpen}
                    aria-label={areaOpen ? `Verberg gebied van maatregel: ${title}` : `Toon gebied van maatregel: ${title}`}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>{areaOpen ? `Verberg gebied van maatregel: ${title}` : `Toon gebied van maatregel: ${title}`}</title>
                        <circle
                            cx="12"
                            cy="12"
                            r="11"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                        />
                        {!areaOpen && (
                            <rect x="11" y="8" width="2" height="8" fill="currentColor"/>
                        )}
                        <rect
                            x="16"
                            y="11"
                            width="2"
                            height="8"
                            transform="rotate(90 16 11)"
                            fill="currentColor"
                        />
                    </svg>
                </button>}
                <div
                    className={`dispositionCard__area-expand ${areaOpen ? "dispositionCard__area-expand--open" : ""}`}
                    role="region"
                    aria-labelledby="area-label"
                >
                    <span id="area-label" className="visually-hidden">Gebied details</span>
                    <ul className="dispositionCard__area-list">
                        {areas.map((a, i) => (
                            <li
                                className={`dispositionCard__area-list-item ${gebied.includes(a.value) ? "dispositionCard__area-list-item--active" : ""}`}
                                key={i}
                            >
                                <AreaIcon id={a.value} aria-hidden="true" />
                                {a.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </li>

            <li
                className={`dispositionCard__image ${contentOpen ? "dispositionCard__image--open-content" : ""} ${areaOpen ? "dispositionCard__image--open-area" : ""}`}
                style={{
                    backgroundImage: image === "" ? "" : `url(${process.env.REACT_APP_HOST}${image})`
                }}
                aria-hidden="true"
            ></li>
        </ul>
    );
};

export default DispositionCard;
